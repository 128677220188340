import React, { useEffect } from 'react'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { baseIP } from '../config/axios'
import { loginAdmin } from '../redux/features/admin/adminAuthSlice'
import NotFound from '../pages/admin/404/NotFound'
import Home from '../pages/admin/home/Home'
import ZoneList from '../pages/admin/zone/ZoneList'
import CustomerList from '../pages/admin/customer-list/CustomerList'
import ViewEditCustomer from '../pages/admin/view-edit-customer/ViewEditCustomer'
import CustomerDetails from '../pages/admin/customer-details/CustomerDetails'


const Admin = () => {
    const { admin } = useSelector((state) => state.adminAuth)
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()
    let isAuthenticated = false

    if (admin?.token) {
        isAuthenticated = true
    }

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id && !admin?._id) {
            window.location.href = `http://staff.alliancedev.in`
        } else if (id || admin?._id) {
            dispatch(loginAdmin(id || admin?._id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Routes>
            {/* Home */}
            <Route path='/' element={<PrivateRoute element={<Home />} isAuthenticated={isAuthenticated} />} />
            {/* Zone */}
            <Route path='/zone-list' element={<PrivateRoute element={<ZoneList />} isAuthenticated={isAuthenticated} />} />

            {/* Customer */}
            <Route path='/customer-list' element={<PrivateRoute element={<CustomerList />} isAuthenticated={isAuthenticated} />} />
            <Route path='/customer-list/:cid/view' element={<PrivateRoute element={<ViewEditCustomer />} isAuthenticated={isAuthenticated} />} />
            <Route path='/customer-list/:cid/cards' element={<PrivateRoute element={<CustomerDetails />} isAuthenticated={isAuthenticated} />} />

            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

function PrivateRoute({ element, isAuthenticated }) {
    return isAuthenticated && (
        <Routes>
            <Route path='/' element={element} />
        </Routes>
    )

}

export default Admin